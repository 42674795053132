import { render, staticRenderFns } from "./InsProductAttrsTree.vue?vue&type=template&id=d222573e&scoped=true&"
import script from "./InsProductAttrsTree.vue?vue&type=script&lang=ts&"
export * from "./InsProductAttrsTree.vue?vue&type=script&lang=ts&"
import style0 from "./InsProductAttrsTree.vue?vue&type=style&index=0&lang=less&"
import style1 from "./InsProductAttrsTree.vue?vue&type=style&index=1&id=d222573e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d222573e",
  null
  
)

export default component.exports